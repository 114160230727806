/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-dialog-container {
    max-height: '90vh';
    overflow: scroll;
}


/* Base styles for the badge */
.badge {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1;
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
    text-transform: uppercase;
    white-space: nowrap;
}

/* Status-specific styles */
.badge--CREATED {
    background-color: #e0f2f1;
    /* Light teal */
    color: #00695c;
    /* Dark teal */
}

.badge--PENDING {
    background-color: #fff8e1;
    /* Light yellow */
    color: #f9a825;
    /* Amber */
}

.badge--CANCELLED {
    background-color: #ffebee;
    /* Light red */
    color: #c62828;
    /* Dark red */
}

.badge--COMPLETED {
    background-color: #e8f5e9;
    /* Light green */
    color: #2e7d32;
    /* Dark green */
}